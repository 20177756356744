


.chart-container {
    background-color: #ffffff;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
    position: relative;
  }
  
  .chart-wrapper {
    height: 600px;
    transition: height 0.3s ease;
  }
  
  .chart-container.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 9999;
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    box-sizing: border-box;
    padding: 20px;
  }
  
  .chart-wrapper.fullscreen {
    transform: scale(1.1);
    transition: transform 0.3s ease;
  }
  
  .chart-wrapper.fullscreen .apexcharts-tooltip {
    display: none;
  }
  
  .maximize-button {
    position: absolute;
    bottom: 10px;
    right: 10px;
    padding: 10px;
    background-color: #f0f0f0;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease;
  }
  
  .maximize-button:hover {
    background-color: #e0e0e0;
  }
  
  .maximize-button:focus {
    outline: none;
  }
  

  .chrt_title_dots{
    display: flex;
  justify-content: space-between;
  }
  .three_dots{
    margin-top: 18px;
  }