/* @font-face {
  font-family: 'Roboto';
  src: url(${fontFile});
} */



/* CSS for table search and entries */
.dataTables_wrapper .dataTables_filter input {
    width: 150px; /* Adjust the width as needed */
    margin-bottom: 30px;
    margin-top: px;
   
  }
  
  .dataTables_wrapper .dataTables_length select {
    width: 100px; /* Adjust the width as needed */
    /* margin-bottom: 30px;
    margin-top: 20px; */
  }
  
  /* CSS for table responsiveness and margin */
  .table-responsive {
    overflow-x: auto;
    margin-top: 20px;
    margin-bottom: 20px; /* Adjust the margin as needed */
  }
  
  /* Optional: Add styles for the table itself */
  table.table {
    width: 100%;
    border-collapse: collapse;
 
    
  }
  
  table.table th,
  table.table td {
    padding: 8px;
    border: 1px solid #ddd;
  
  }
  
  table.table th {
    background-color: #f2f2f2;
   
  }
  