.count__card{
    display: flex;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    height: 157px;
    width: 300px;
    background-color: #17a2b8;
    margin-top: 17%;
}
.main__card{
    position: relative;
}
.inner{
    display: flex;
    justify-content: space-between;
    
    
}

.count__data{
    text-align: initial;
    margin-left: 23px;
    /* font-size: 30px; */
    color: white;
}
.count__data span{
    font-size: 41px;

}.count__data p{
    font-size: 12px;
}
.icon{
    font-size: 50px;
    margin-right: 20px;
    display: flex;
    justify-content: end;
    color: white;
    align-items: center;
}
.more__info {
    color: white;
    font-size: 20px;
    background-color: #1591a5;
    position: absolute;
    width: 100%;
    bottom: 0;
    cursor: pointer;
    transition: 0.3s ease-in;
     
}
.more__info:hover{
    background-color:#1592a5b4;
}
.card__title{
    text-shadow: 1px 1px #9d9595;
    margin: "30px";
    padding: 10px;
        
}
.chart-page {
    background-color: #f2f2f2; 
  }
.export-buttons{
    display: flex;
    justify-content: end;
    gap: 8px;
}