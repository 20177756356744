.header {
    height: $height-header;
  
    .container {
      padding-left: 20px;
      padding-right: 20px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      @include media-breakpoint-up(lg) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  
    .nav-icon {
      .nav-link {
        padding: 0;
        font-size: 21px;
        line-height: 1;
        color: $secondary;
        @include transition(all 0.25s);
  
        + .nav-link { margin-left: 8px; }
  
        &:hover {
          color: $primary;
          transform: scale(1.1);
        }
      }
    }
  }
  
  .header-logo {
    font-size: 24px;
    font-family: $ff-secondary;
    font-weight: 600;
    display: inline-block;
    line-height: 1;
    color: $dark;
    letter-spacing: -.5px;
    position: relative;
  
    &:hover,
    &:focus { color: $dark; }
  
    &::after {
      content: '';
      position: absolute;
      bottom: 3.5px;
      right: -8px;
      width: 6px;
      height: 6px;
      border-radius: 100%;
      background-color: $primary;
    }
  }
  
  .header-main {
    height: $height-header;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 800;
    border-bottom: 1px solid $gray-200;
    background-color: #fff;
    display: flex;
    align-items: center;
    @include transition(all 0.25s);
    box-shadow: 0 1px 1px rgba($gray-900, 0.01),
                0 4px 4px rgba($gray-900, 0.01),
                0 16px 16px rgba($gray-900, 0.01);
  
    @include media-breakpoint-up(lg) {
      left: $sidebar-width - 10px;
      border-left: 1px solid $gray-100;
    }
  
    @include media-breakpoint-up(xxl) { left: $sidebar-width; }
  
    .form-search {
      width: 180px;
      box-shadow: none;
      background-color: $gray-200;
  
      @include media-breakpoint-down(md) {
        height: $height-base + 2px;
        border-radius: 4px;
      }
  
      @include media-breakpoint-up(sm) { width: 270px; }
      @include media-breakpoint-up(xl) { width: 320px; }
  
      .form-control { padding-left: 3px; }
      i { opacity: .6; }
  
      &.onfocus { background-color: #fff; }
    }
  
    .menu-link {
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 1.325rem;
      color: $secondary;
  
      &:hover { color: $primary; }
    }
  
    .nav-link {
      color: $secondary;
      &:hover { color: $body-color; }
  
      &.active {
        font-weight: $font-weight-medium;
        color: $primary;
        letter-spacing: -.1px;
      }
  
      .badge {
        color: $primary;
        border: 1px solid $primary;
        border-radius: 4px;
        padding: 3px;
        margin-left: 2px;
      }
    }
  
    .dropdown-link {
      display: block;
      color: $secondary;
      position: relative;
  
      &:hover { color: $gray-700; }
  
      i {
        font-size: 20px;
        line-height: 1.4;
      }
  
      small {
        position: absolute;
        top: -2px;
        right: -4px;
        width: 14px;
        height: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        background-color: $danger;
        color: #fff;
        font-size: 9px;
        font-family: $font-family-sans-serif;
        box-shadow: 0 0 0 1px #fff;
      }
    }
  }
  
  .dropdown-skin {
    .dropdown-link {
      display: flex;
      margin-top: 2px;
  
      i {
        font-size: 22px;
        animation: rotation 5s infinite linear;
        color: $primary;
      }
  
      @keyframes rotation {
        from { transform: rotate(0deg); }
        to { transform: rotate(359deg); }
      }
    }
  
    .dropdown-menu {
      width: 250px;
      margin-right: 10px;
      padding: 20px;
      border-color: $border-color;
      border-radius: 6px;
      border-width: 0;
      box-shadow: 0 2px 10px rgba($gray-600, 0.1),
                  0 10px 20px rgba($gray-600, 0.1);
  
      label {
        font-size: 10px;
        font-weight: 500;
        font-family: $ff-numerals;
        color: $secondary;
        text-transform: uppercase;
        display: block;
        letter-spacing: .5px;
        margin-bottom: 10px;
        line-height: 1.2;
      }
  
      hr {
        border-color: $gray-300;
        opacity: 1;
        margin: 20px 0;
      }
    }
  }
  
  .nav-skin {
    flex-wrap: nowrap;
    white-space: nowrap;
  
    .nav-link {
      border: 1px solid $gray-400;
      font-size: $font-size-sm;
      padding: 4px 15px;
      color: $gray-700;
  
      &:hover,
      &:focus {
        border-color: $gray-500;
        background-color: $gray-200;
        position: relative;
      }
  
      + .nav-link { margin-left: -1px; }
  
      &:first-child {
        border-top-left-radius: 3px;
        border-bottom-left-radius: 3px;
      }
  
      &:last-child {
        border-top-right-radius: 3px;
        border-bottom-right-radius: 3px;
      }
  
      &.active {
        letter-spacing: normal;
        font-weight: 400;
        border-color: $secondary;
        background-color: $gray-300;
        color: $dark;
        position: relative;
        z-index: 2;
      }
    }
  }
  
  .dropdown-notification {
    position: relative;
  
    .dropdown-link { margin-top: 5px; }
  
    .dropdown-menu {
      margin-right: 10px;
      width: 320px;
      padding: 0;
      border-color: $border-color;
      border-radius: 6px;
      border-width: 0;
      box-shadow: 0 2px 10px rgba($gray-600, 0.1),
                  0 10px 20px rgba($gray-600, 0.1);
  
      @include media-breakpoint-up(sm) { width: 340px; }
    }
  
    .dropdown-menu-header {
      height: 48px;
      border-bottom: 1px solid $border-color;
      display: flex;
      align-items: center;
      padding: 0 15px;
    }
  
    .dropdown-menu-title {
      font-weight: $font-weight-semibold;
      font-size: 15px;
      color: $dark;
      margin-bottom: 0;
    }
  
    .list-group {
      padding: 5px;
    }
  
    .list-group-item {
      border-width: 0;
      display: flex;
      padding: 8px 10px;
      border-radius: 4px;
      background-color: transparent;
  
      &:hover {
        background-color: rgba($gray-200, .85);
        cursor: pointer;
      }
  
      strong { font-weight: $font-weight-semibold; }
    }
  
    .avatar {
      margin-right: 15px;
      flex-shrink: 0;
    }
  
    .list-group-body {
      color: $gray-800;
      line-height: 1.4;
      padding-right: 10px;
  
      p { margin-bottom: 2px; }
  
      span {
        font-size: 11px;
        color: $secondary;
      }
    }
  
    .dropdown-menu-footer {
      text-align: center;
      padding: 8px 10px;
      border-top: 1px solid $border-color;
      font-size: $font-size-sm;
    }
  }
  
  .dropdown-profile {
    .dropdown-link {
      .avatar {
        width: 34px;
        height: 34px;
      }
    }
  
    .dropdown-menu {
      width: 230px;
      padding: 0;
      border-color: $border-color;
      border-radius: 6px;
      border-width: 0;
      box-shadow: 0 2px 10px rgba($gray-600, 0.1),
                  0 10px 20px rgba($gray-600, 0.1);
    }
  
    .dropdown-menu-body {
      padding: 20px 20px 15px;
  
      h5 { font-size: 16px; }
    }
  
    .nav {
      flex-direction: column;
      margin: 0 -10px;
    }
  
    .nav a {
      display: flex;
      align-items: center;
      padding: 7px 8px;
      color: $gray-700;
      border-radius: 4px;
      @include transition(all 0.25s);
  
      &:hover {
        background-color: $gray-200;
        color: $gray-900;
      }
  
      i {
        font-size: 20px;
        line-height: 1;
        margin-right: 10px;
      }
    }
  
    hr {
      opacity: .12;
      margin: 5px -2px;
    }
  }
  